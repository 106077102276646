import React, { PureComponent } from "react";
import abcjsObj, { AbcVisualParams } from "abcjs";

interface AbcjsProps {
  abcNotation: string;
  abcVisualParams?: AbcVisualParams;
}

class Abcjs extends PureComponent<AbcjsProps> {
  uniqueNumber = Date.now() + Math.random();

  renderAbcNotation(abcNotation: string, abcVisualParams?: AbcVisualParams) {
    abcjsObj.renderAbc(
      "abcjs-result-" + this.uniqueNumber,
      abcNotation,
      abcVisualParams
    );
  }

  componentDidMount() {
    const { abcNotation, abcVisualParams } = this.props;
    this.renderAbcNotation(abcNotation, abcVisualParams);
  }

  componentDidUpdate() {
    const { abcNotation, abcVisualParams } = this.props;
    this.renderAbcNotation(abcNotation, abcVisualParams);
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <div
          id={"abcjs-result-" + this.uniqueNumber}
          style={{ width: "100%" }}
        />
      </div>
    );
  }
}

export default Abcjs;
