import {
  filterValidNoteGenerationMode,
  getNoteGenerationModes,
  isRandomNoteGenerationMode,
  NoteGenerationMode,
  noteGenerationModeMap,
} from "./controls";
import {
  filterValidTimeSignatureId,
  getPatternCount,
  getTimeSignature,
  TimeSignatureId,
  timeSignatures,
} from "./timeSignature";
import { getPatternIds, PatternId } from "./patterns";
import { FC, Fragment } from "react";

interface GrooveGeneratorControlsProps {
  timeSignatureId: TimeSignatureId;
  setTimeSignatureId: (value: TimeSignatureId) => void;
  patternIds: PatternId[];
  setPatternIds: (value: PatternId[]) => void;
  randomisePatternIds: () => void;
  hiHatPattern: boolean[];
  setHiHatPattern: (value: boolean[]) => void;
  noteGenerationMode: NoteGenerationMode;
  setNoteGenerationMode: (value: NoteGenerationMode) => void;
  randomiseNotes: () => void;
  bassOnDownBeat: boolean;
  setBassOnDownBeat: (value: boolean) => void;
  snareOnBackBeat: boolean;
  setSnareOnBackBeat: (value: boolean) => void;
  noteGenerationModeClassName: string;
  forceNotesClassName: string;
}

const GrooveGeneratorControls: FC<GrooveGeneratorControlsProps> = ({
  timeSignatureId,
  setTimeSignatureId,
  patternIds,
  setPatternIds,
  randomisePatternIds,
  hiHatPattern,
  setHiHatPattern,
  noteGenerationMode,
  setNoteGenerationMode,
  randomiseNotes,
  bassOnDownBeat,
  setBassOnDownBeat,
  snareOnBackBeat,
  setSnareOnBackBeat,
  noteGenerationModeClassName,
  forceNotesClassName,
}) => {
  const timeSignature = getTimeSignature(timeSignatureId);
  return (
    <div className="card mt-4 mb-4 mb-md-1">
      <h2 className="h5 card-header">Generate Groove</h2>
      <div className="card-body pb-0">
        <div className="row gx-3">
          <div data-field="bl" className="col-md-3 col-sm-6 mb-3 order-first">
            <div className="input-group">
              <button
                className="btn btn-primary"
                type="button"
                title="Randomise"
                onClick={(e) => {
                  randomisePatternIds();
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                }}
              >
                <i className="fa fa-random fs-4 mx-2" />
              </button>
              <div className="form-floating">
                <input
                  className="form-control"
                  id="groove-generator-controls-beat-ids"
                  type="text"
                  placeholder="AAAA"
                  value={patternIds
                    .slice(0, getPatternCount(timeSignature))
                    .join("")}
                  maxLength={getPatternCount(timeSignature)}
                  onChange={(e) => {
                    const newBeatIds = e.currentTarget.value
                      .toUpperCase()
                      .split("")
                      .filter((c) => getPatternIds().includes(c));
                    setPatternIds(newBeatIds);
                  }}
                />
                <label htmlFor="groove-generator-controls-beat-ids">
                  Beat letters
                </label>
              </div>
            </div>
          </div>
          <div
            data-field="ng"
            className={`${noteGenerationModeClassName} col-md-6 mb-3 order-sm-4 order-md-4`}
          >
            <div className="input-group">
              <button
                className={`btn btn-${
                  isRandomNoteGenerationMode(noteGenerationMode)
                    ? "primary"
                    : "secondary"
                }`}
                disabled={!isRandomNoteGenerationMode(noteGenerationMode)}
                type="button"
                title="Randomise"
                onClick={randomiseNotes}
              >
                <i className="fa fa-random fs-4 mx-2" />
              </button>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="groove-generator-controls-ngm"
                  value={noteGenerationMode}
                  onChange={(e) =>
                    setNoteGenerationMode(
                      filterValidNoteGenerationMode(e.currentTarget.value) ??
                        "r"
                    )
                  }
                >
                  {getNoteGenerationModes().map((ngm) => (
                    <option key={ngm} value={ngm}>
                      {noteGenerationModeMap[ngm]}
                    </option>
                  ))}
                </select>
                <label htmlFor="groove-generator-controls-beat-ngm">
                  Note generation rules
                </label>
              </div>
            </div>
          </div>
          <div data-field="ts" className="col-md-5 mb-3 order-sm-5 order-md-2">
            <div className="form-floating">
              <select
                className="form-select"
                id="groove-generator-controls-time-signature"
                value={timeSignatureId}
                onChange={(e) =>
                  setTimeSignatureId(
                    filterValidTimeSignatureId(e.currentTarget.value) ?? "44"
                  )
                }
              >
                {Array.from(
                  new Set(timeSignatures.map((ts) => ts.notation))
                ).map((notation) => (
                  <optgroup key={notation} label={notation}>
                    {timeSignatures
                      .filter((ts) => ts.notation === notation)
                      .map((ts) => (
                        <option key={ts.id} value={ts.id}>
                          {ts.display}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </select>
              <label htmlFor="groove-generator-controls-time-signature">
                Time signature
              </label>
            </div>
          </div>
          <div data-field="hh" className="col-md-4 col-sm-6 mb-3 order-sm-3">
            <div className="form-floating">
              <div className="form-control-plaintext border rounded text-nowrap overflow-x-auto">
                {hiHatPattern.map((checked, index) => (
                  <Fragment key={index}>
                    {index > 0 && index % 4 === 0 && (
                      <span className="me-2 opacity-50">·</span>
                    )}
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checked}
                      aria-label={`Play hi-hat on subdivision ${index + 1}`}
                      onChange={(e) => {
                        const pattern = [...hiHatPattern];
                        pattern[index] = e.currentTarget.checked;
                        setHiHatPattern(pattern);
                      }}
                    />
                  </Fragment>
                ))}
                <div
                  className="d-flex p-2"
                  style={{ position: "absolute", top: "0", right: "0" }}
                >
                  <button
                    className="btn btn-small btn-outline-secondary rounded-1 me-1"
                    onClick={() => {
                      setHiHatPattern(
                        hiHatPattern.slice(
                          0,
                          Math.max(hiHatPattern.length - 1, 1)
                        )
                      );
                    }}
                    style={
                      {
                        "--bs-btn-padding-y": "0rem",
                        "--bs-btn-padding-x": ".1rem",
                        "--bs-btn-font-size": ".54rem",
                      } as any
                    }
                  >
                    <i className="fa fa-fw fa-minus" />
                  </button>
                  <button
                    className="btn btn-small btn-outline-secondary rounded-1"
                    onClick={() => {
                      setHiHatPattern([...hiHatPattern, false]);
                    }}
                    style={
                      {
                        "--bs-btn-padding-y": "0rem",
                        "--bs-btn-padding-x": ".1rem",
                        "--bs-btn-font-size": ".54rem",
                      } as any
                    }
                  >
                    <i className="fa fa-fw fa-plus" />
                  </button>
                </div>
              </div>
              <label>Hi-hat pattern</label>
            </div>
          </div>
          <div
            data-field="fn"
            className={`${forceNotesClassName} col-md-6 mb-3 order-last`}
          >
            <div className="form-floating">
              <select
                className="form-select"
                value={`${bassOnDownBeat ? "b" : ""}${
                  snareOnBackBeat ? "s" : ""
                }`}
                onChange={(e) => {
                  setBassOnDownBeat(e.currentTarget.value.includes("b"));
                  setSnareOnBackBeat(e.currentTarget.value.includes("s"));
                }}
              >
                <option value="">None</option>
                <option value="b">Bass on downbeat</option>
                <option value="s">Snare on backbeat</option>
                <option value="bs">Bass on downbeat, snare on backbeat</option>
              </select>
              <label>Force notes</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrooveGeneratorControls;
