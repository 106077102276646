import {
  filterValidNoteGenerationMode,
  isRandomNoteGenerationMode,
  NoteGenerationMode,
} from "./controls";
import {
  defaultTimeSignatureId,
  getTimeSignatureOrDefault,
  TimeSignatureId,
} from "./timeSignature";
import { getPatternIds, PatternId } from "./patterns";
import { BeatTemplate, generateBeatTemplateSubdivisions } from "./beat";
import { NoteTemplate, validNote } from "./note";

interface State {
  noteGenerationMode: NoteGenerationMode;
  bassOnDownBeat: boolean;
  snareOnBackBeat: boolean;
  timeSignatureId: TimeSignatureId;
  patternIds: PatternId[];
  hiHatPattern: boolean[];
  beatTemplates: BeatTemplate[];
}

export const serialize = (state: State): string => {
  const beatIds = state.patternIds.join("").toLowerCase();
  const beatData =
    state.timeSignatureId === defaultTimeSignatureId
      ? beatIds
      : `${beatIds}-${state.timeSignatureId}`;
  const beatHiHatPattern = state.hiHatPattern
    .map((s) => (s ? "x" : "-"))
    .join("");
  const controls = [
    state.noteGenerationMode,
    state.bassOnDownBeat ? "y" : "n",
    state.snareOnBackBeat ? "y" : "n",
  ].join("");
  const beatTemplatesString = state.beatTemplates
    .map((beatSubdivisions) =>
      beatSubdivisions.subdivisions.map((notes) => notes.join("")).join("x")
    )
    .join("-");
  const pathSegments = [beatData, beatHiHatPattern, controls];
  if (isRandomNoteGenerationMode(state.noteGenerationMode)) {
    pathSegments.push(beatTemplatesString);
  }
  return beatIds.length === 0 ? "" : `#${pathSegments.join("/")}`;
};

export const deserialize = (fragment: string): State => {
  const segments = fragment.slice(1).split("/");
  const validPatternIds = getPatternIds();
  const patternIds = segments[0]
    .split("-")[0]
    .toUpperCase()
    .split("")
    .filter((c) => validPatternIds.includes(c));
  const providedTimeSignatureId: TimeSignatureId = segments[0].split("-")[1];
  const timeSignature = getTimeSignatureOrDefault(providedTimeSignatureId);
  const hiHatPattern = (segments[1] ?? "")
    .toLowerCase()
    .split("")
    .map((s) => s === "x");
  const controls = segments[2] ?? "";
  const noteGenerationMode: NoteGenerationMode =
    filterValidNoteGenerationMode(controls[0]) ?? "r";
  const bassOnDownBeat = controls[1] !== "n";
  const snareOnBackBeat = controls[2] !== "n";
  const beatsString = segments[3] ?? "";
  const providedBeats = beatsString
    .split("-")
    .map((beatSubdivisionsString) =>
      beatSubdivisionsString
        .split("x")
        .map(
          (notesString) =>
            notesString
              .split("")
              .filter((note) => validNote(note)) as NoteTemplate[]
        )
    );
  const beatTemplates: BeatTemplate[] = timeSignature.barBeats.map(
    (b, index) => {
      const providedSubdivisions = providedBeats[index] ?? [];
      const beatSubdivisions: NoteTemplate[][] =
        providedSubdivisions.length >= b.subdivisionCount
          ? providedSubdivisions.slice(0, b.subdivisionCount)
          : generateBeatTemplateSubdivisions(
              noteGenerationMode,
              b.subdivisionCount
            );
      return {
        type: b.beatType,
        subdivisions: beatSubdivisions,
      };
    }
  );

  return {
    noteGenerationMode,
    bassOnDownBeat,
    snareOnBackBeat,
    timeSignatureId: timeSignature.id,
    patternIds,
    hiHatPattern,
    beatTemplates,
  };
};

export default State;
