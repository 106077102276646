import React, { FC, useEffect, useState } from "react";
import GrooveGeneratorControls from "./GrooveGeneratorControls";
import GrooveComponent from "./GrooveComponent";
import { deserialize, serialize } from "./State";
import { isRandomNoteGenerationMode, NoteGenerationMode } from "./controls";
import { calculateGrooveNotationData } from "./notation";
import { getTimeSignature, TimeSignatureId } from "./timeSignature";
import { initialHash, basePath } from "./initialState";
import { generateRandomPatternIds, PatternId } from "./patterns";
import {
  BeatTemplate,
  cloneBeatTemplates,
  generateGroove,
  generateRandomBeatTemplates,
} from "./beat";
import { convertNotesToNoteTemplates, NoteTemplate } from "./note";
import "./GrooveGenerator.css";

const initialState = deserialize(initialHash);

interface GrooveGeneratorProps {
  firstIncludedPatternId: PatternId;
  lastIncludedPatternId: PatternId;
}

const GrooveGenerator: FC<GrooveGeneratorProps> = ({
  firstIncludedPatternId,
  lastIncludedPatternId,
}) => {
  const [noteGenerationMode, setNoteGenerationMode] =
    useState<NoteGenerationMode>(initialState.noteGenerationMode);
  const [bassOnDownBeat, setBassOnDownBeat] = useState<boolean>(
    initialState.bassOnDownBeat
  );
  const [snareOnBackBeat, setSnareOnBackBeat] = useState<boolean>(
    initialState.snareOnBackBeat
  );
  const [timeSignatureId, setTimeSignatureId] = useState<TimeSignatureId>(
    initialState.timeSignatureId
  );
  const timeSignature = getTimeSignature(timeSignatureId);
  const [patternIds, setPatternIds] = useState<PatternId[]>(
    initialState.patternIds.length > 0
      ? initialState.patternIds
      : generateRandomPatternIds({
          timeSignature,
          bassOnDownBeat,
          snareOnBackBeat,
          firstIncludedPatternId,
          lastIncludedPatternId,
        })
  );
  const [hiHatPattern, setHiHatPattern] = useState<boolean[]>(
    initialState.hiHatPattern.length > 0
      ? initialState.hiHatPattern
      : [true, false, true, false]
  );
  const [beatTemplates, setBeatTemplates] = useState<BeatTemplate[]>(
    initialState.beatTemplates.length >= timeSignature.barBeats.length
      ? initialState.beatTemplates
      : generateRandomBeatTemplates(timeSignature, noteGenerationMode)
  );
  const [highlightNoteGenerationMode, setHighlightNoteGenerationMode] =
    useState<boolean>(false);
  const [highlightForceNotes, setHighlightForceNotes] =
    useState<boolean>(false);

  const groove = generateGroove({
    beatTemplates,
    patternIds,
    hiHatPattern,
    bassOnDownBeat,
    snareOnBackBeat,
  });

  const randomisePatternIds = () => {
    setPatternIds(
      generateRandomPatternIds({
        timeSignature,
        bassOnDownBeat,
        snareOnBackBeat,
        firstIncludedPatternId,
        lastIncludedPatternId,
      })
    );
  };

  const randomiseNoteTypes = (noteGenerationMode: NoteGenerationMode) => {
    setBeatTemplates(
      generateRandomBeatTemplates(timeSignature, noteGenerationMode)
    );
  };

  const toggleNoteType = (notationIndex: number) => {
    // Notes can only be toggled if we're in a random mode
    if (!isRandomNoteGenerationMode(noteGenerationMode)) {
      setHighlightNoteGenerationMode((isHighlighted) => {
        if (!isHighlighted) {
          window.setTimeout(() => setHighlightNoteGenerationMode(false), 2000);
        }
        return true;
      });
      return;
    }

    // The notation data helps us to identify the original beat and subdivision from the note that was clicked
    const grooveNotationData = calculateGrooveNotationData(
      timeSignature,
      groove
    );
    const notationData = grooveNotationData.flat(2)[notationIndex];

    // Get current notes from the finished groove so we can see the current visual state after overrides
    const currentGrooveNotes = [
      ...(groove[notationData.beatIndex]?.subdivisions[
        notationData.subdivisionIndex
      ]?.notes ?? []),
    ];
    const currentNotes = convertNotesToNoteTemplates(currentGrooveNotes);
    if (currentNotes.length === 0) {
      return;
    }

    // Determine whether there are restrictions on the toggling of this subdivision
    const isFirstSubdivisionOfBeat = notationData.subdivisionIndex === 0;
    const beatType = groove[notationData.beatIndex].type;
    const requireBass =
      isFirstSubdivisionOfBeat && beatType === "downBeat" && bassOnDownBeat;
    const requireSnare =
      isFirstSubdivisionOfBeat && beatType === "backBeat" && snareOnBackBeat;
    const isSubdivisionLocked =
      noteGenerationMode === "r" && (requireBass || requireSnare);

    // Abort if no change can be made to this subdivision
    // - Only applies to generation mode "r"
    // - For generation mode "a" we can always make a change because we can toggle between one and two notes
    if (isSubdivisionLocked) {
      setHighlightForceNotes((isHighlighted) => {
        if (!isHighlighted) {
          window.setTimeout(() => setHighlightForceNotes(false), 2000);
        }
        return true;
      });
      return;
    }

    let newNotes: NoteTemplate[] = [];
    if (noteGenerationMode === "a") {
      // Generation mode "a" has up to three states depending on restrictions and we want to make sure something
      // changes on every click
      if (currentNotes.includes("b") && currentNotes.includes("s")) {
        if (requireSnare) {
          newNotes.push("s");
        } else {
          newNotes.push("b");
        }
      } else if (currentNotes.includes("s")) {
        newNotes.push("b");
        newNotes.push("s");
      } else if (currentNotes.includes("b")) {
        newNotes.push("s");
        if (requireBass) {
          newNotes.push("b");
        }
      }
    } else if (noteGenerationMode === "r") {
      // Generation mode "r" only has two possible states and we will already have aborted if no change can be made
      if (currentNotes.includes("b")) {
        newNotes.push("s");
      } else if (currentNotes.includes("s")) {
        newNotes.push("b");
      }
    }
    const newBeatTemplates = cloneBeatTemplates(beatTemplates);
    newBeatTemplates[notationData.beatIndex].subdivisions[
      notationData.subdivisionIndex
    ] = newNotes;
    setBeatTemplates(newBeatTemplates);
  };

  useEffect(() => {
    const hash = serialize({
      noteGenerationMode,
      bassOnDownBeat,
      snareOnBackBeat,
      timeSignatureId,
      patternIds,
      hiHatPattern,
      beatTemplates,
    });
    window.history.replaceState(undefined, "", `${basePath}${hash}`);
  }, [
    noteGenerationMode,
    bassOnDownBeat,
    snareOnBackBeat,
    timeSignatureId,
    patternIds,
    hiHatPattern,
    beatTemplates,
  ]);

  return (
    <div className="my-5">
      <GrooveGeneratorControls
        {...{
          timeSignatureId,
          patternIds,
          setPatternIds,
          hiHatPattern,
          setHiHatPattern,
          noteGenerationMode,
          bassOnDownBeat,
          setBassOnDownBeat,
          snareOnBackBeat,
          setSnareOnBackBeat,
        }}
        randomiseNotes={() => randomiseNoteTypes(noteGenerationMode)}
        randomisePatternIds={() => {
          randomisePatternIds();
          randomiseNoteTypes(noteGenerationMode);
        }}
        setNoteGenerationMode={(noteGenerationMode: NoteGenerationMode) => {
          setNoteGenerationMode(noteGenerationMode);
          randomiseNoteTypes(noteGenerationMode);
        }}
        setTimeSignatureId={(timeSignatureId: TimeSignatureId) => {
          setTimeSignatureId(timeSignatureId);
          setBeatTemplates(
            generateRandomBeatTemplates(
              getTimeSignature(timeSignatureId),
              noteGenerationMode
            )
          );
        }}
        noteGenerationModeClassName={
          highlightNoteGenerationMode ? "highlight" : ""
        }
        forceNotesClassName={highlightForceNotes ? "highlight" : ""}
      />
      <GrooveComponent
        timeSignature={timeSignature}
        beats={groove}
        onClick={toggleNoteType}
      />
    </div>
  );
};

export default GrooveGenerator;
