import GrooveGenerator from "./GrooveGenerator";
import BeatsComponent from "./BeatsComponent";
import ThemeToggle from "./ThemeToggle";
import { basePath } from "./initialState";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const cookieMaxAgeInSeconds = 365 * 24 * 60 * 60;
const initialTheme = cookies.get("theme");
const initialIncludedBeatIds = cookies.get("includedBeatIds");

const beatOptions = [
  {
    value: "A-C",
    text: "8th notes only",
  },
  {
    value: "A-E",
    text: "8th notes or one 16th note",
  },
  {
    value: "A-J",
    text: "One or two notes",
  },
  {
    value: "A-O",
    text: "Any notes",
  },
];

const App = () => {
  const [theme, setTheme] = useState<"light" | "dark">(
    ["light", "dark"].includes(initialTheme) ? initialTheme : "light"
  );
  const [includedBeatIds, setIncludedBeatIds] = useState(
    beatOptions.map((o) => o.value).includes(initialIncludedBeatIds)
      ? initialIncludedBeatIds
      : "A-O"
  );
  const [firstIncludedBeatId, lastIncludedBeatId] = includedBeatIds.split("-");

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
    cookies.set("theme", theme, { path: "/", maxAge: cookieMaxAgeInSeconds });
  }, [theme]);

  useEffect(() => {
    cookies.set("includedBeatIds", includedBeatIds, {
      path: "/",
      maxAge: cookieMaxAgeInSeconds,
    });
  }, [includedBeatIds]);

  const headerText = "The Hardy Groove System";

  return (
    <div>
      <div className="navbar navbar-expand-lg bg-body-tertiary border-bottom">
        <div className="container-fluid" style={{ maxWidth: "1000px" }}>
          <a className="navbar-brand" href={basePath}>
            {headerText}
          </a>
          <ul className="navbar-nav">
            <li className="nav-item">
              <ThemeToggle theme={theme} setTheme={setTheme} />
            </li>
          </ul>
        </div>
      </div>
      <div className="container-fluid" style={{ maxWidth: "1000px" }}>
        <div
          className="modal fade"
          id="more-info-modal"
          tabIndex={-1}
          aria-labelledby="more-info-modal-label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="more-info-modal-label">
                  How it works
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body pb-0">
                <h2 className="h5">Beats</h2>
                <p>
                  The lettered building blocks in the <b>Beats</b> section each
                  last one beat and show combinations of quarter, eighth and
                  sixteenth note beat patterns. Each pattern is shown as a snare
                  drum against eighth note hi-hats.
                </p>
                <h2 className="h5">Generate Groove</h2>
                <p>
                  You can use the <b>Generate Groove</b> controls to combine the
                  beats in novel ways to generate interesting grooves. The
                  played notes will be distributed across the bass and snare
                  according to the controls you configure. To reset all controls
                  to their defaults, click <a href={basePath}>{headerText}</a>{" "}
                  at the top of the page.
                </p>
                <p>
                  Click the <i className="fa fa-random" /> randomise buttons to
                  generate a new sequence of beats or to redistribute the notes
                  across the bass and snare. You may need to re-randomise after
                  changing the controls in order to get a compliant groove.
                </p>
                <p>
                  If you're finding the patterns too complicated, use the
                  <b> include beats with</b> dropdown to restrict the beats that
                  are shown. This also restricts the beats that are selected by
                  the <i className="fa fa-random" /> randomise button.
                </p>
                <p>
                  If you've generated a random bass and snare pattern but you
                  don't like some of the individual notes, you can tap the note
                  to move it between the bass and snare ledger lines. This will
                  not work for notes that are locked by the <b>Force notes</b>{" "}
                  dropdown.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 py-4 border-bottom">
          <p className="m-0">
            <span className="me-2">
              A system to help you create novel grooves and improve creativity
              in your drumming.
            </span>
            <button
              type="button"
              className="btn btn-link align-baseline link-underline link-underline-opacity-0 p-0"
              data-bs-toggle="modal"
              data-bs-target="#more-info-modal"
            >
              <i className="fa fa-regular fa-circle-question" /> How it works
            </button>
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-sm-5 col-md-7">
            <h2 className="h4 mb-3 mb-md-0">Beats</h2>
          </div>
          <div className="col-sm-7 col-md-5">
            <div className="form-floating mb-3 mb-md-0">
              <select
                className="form-select"
                id="beat-options"
                value={includedBeatIds}
                onChange={(e) => {
                  setIncludedBeatIds(e.currentTarget.value);
                }}
              >
                {beatOptions.map((o) => (
                  <option
                    key={o.value}
                    value={o.value}
                  >{`${o.text} (${o.value})`}</option>
                ))}
              </select>
              <label htmlFor="beat-options">Include beats with</label>
            </div>
          </div>
        </div>
        <BeatsComponent
          firstIncludedPatternId={firstIncludedBeatId}
          lastIncludedPatternId={lastIncludedBeatId}
        />
        <GrooveGenerator
          firstIncludedPatternId={firstIncludedBeatId}
          lastIncludedPatternId={lastIncludedBeatId}
        />
      </div>
    </div>
  );
};

export default App;
