export const noteGenerationModeMap = {
  r: "Random bass or snare",
  a: "Random bass, snare or both",
  b: "Just bass",
  s: "Just snare",
  d: "Bass and snare",
};

export type NoteGenerationMode = keyof typeof noteGenerationModeMap;

export const getNoteGenerationModes = (): NoteGenerationMode[] =>
  Object.keys(noteGenerationModeMap) as NoteGenerationMode[];

export const isRandomNoteGenerationMode = (
  noteGenerationMode: NoteGenerationMode
) => ["r", "a"].includes(noteGenerationMode);

export const filterValidNoteGenerationMode = (
  input: string
): NoteGenerationMode | undefined =>
  Object.keys(noteGenerationModeMap).includes(input)
    ? (input as NoteGenerationMode)
    : undefined;
