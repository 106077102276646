import { FC } from "react";

interface ThemeToggleProps {
  theme: "light" | "dark";
  setTheme: (value: "light" | "dark") => void;
}

const ThemeToggle: FC<ThemeToggleProps> = ({ theme, setTheme }) => {
  return (
    <div className="input-group justify-content-end fs-6">
      <button
        className={`btn btn-sm ${
          theme === "light"
            ? "btn-outline-secondary active"
            : "btn-outline-secondary"
        }`}
        data-bs-toggle="button"
        onClick={() => setTheme("light")}
        style={
          {
            "--bs-btn-padding-y": ".2rem",
            "--bs-btn-padding-x": ".3rem",
            "--bs-btn-font-size": ".75rem",
          } as any
        }
      >
        <i className="fa fa-fw fa-sun" />
      </button>
      <button
        className={`btn btn-sm ${
          theme === "dark"
            ? "btn-outline-secondary active"
            : "btn-outline-secondary"
        }`}
        data-bs-toggle="button"
        onClick={() => setTheme("dark")}
        style={
          {
            "--bs-btn-padding-y": ".2rem",
            "--bs-btn-padding-x": ".3rem",
            "--bs-btn-font-size": ".75rem",
          } as any
        }
      >
        <i className="fa fa-fw fa-moon" />
      </button>
    </div>
  );
};

export default ThemeToggle;
