import { BeatType, TimeSignature } from "./timeSignature";
import { NoteGenerationMode } from "./controls";
import { Note, NoteTemplate } from "./note";
import {
  getPaddedPatternSubdivisions,
  getRepeatedHiHatPattern,
  PatternId,
  PatternSubdivision,
} from "./patterns";

export interface BeatSubdivision {
  notes: Note[];
  patternId: PatternId | undefined; // Pattern ID if this is the first subdivision of a pattern
}

export interface Beat {
  type: BeatType;
  subdivisions: BeatSubdivision[];
}

export interface BeatTemplate {
  type: BeatType;
  subdivisions: NoteTemplate[][];
}

export const getSubdivisionCount = (beats: BeatTemplate[]): number =>
  beats.reduce((acc, beat) => acc + beat.subdivisions.length, 0);

const cloneBeatTemplateSubdivisions = (
  beatTemplateSubdivisions: NoteTemplate[][]
): NoteTemplate[][] => beatTemplateSubdivisions.map((notes) => [...notes]);

export const cloneBeatTemplates = (
  beatTemplates: BeatTemplate[]
): BeatTemplate[] =>
  beatTemplates.map((b) => ({
    type: b.type,
    subdivisions: cloneBeatTemplateSubdivisions(b.subdivisions),
  }));

// Generates a beat of played notes, applying pattern and hi-hat
export const generateBeat = (params: {
  beatTemplate: BeatTemplate;
  patternSubdivisions: PatternSubdivision[];
  hiHatPattern: boolean[];
}): Beat => ({
  type: params.beatTemplate.type,
  subdivisions: params.beatTemplate.subdivisions.map((subdivision, index) => {
    const patternSubdivision = params.patternSubdivisions[index];
    const patternId = patternSubdivision.isFirst
      ? patternSubdivision.patternId
      : undefined;
    const isPlayed = patternSubdivision.isPlayed;
    const isHiHat = params.hiHatPattern[index];
    const newNotes: Note[] = isPlayed ? ([...subdivision] as Note[]) : [];
    if (isHiHat) {
      newNotes.push("h");
    }
    return {
      notes: newNotes,
      patternId,
    };
  }),
});

// Generates a groove of played notes, applying pattern, hi-hat and force overrides to the provided beats
export const generateGroove = (params: {
  beatTemplates: BeatTemplate[];
  patternIds: PatternId[];
  hiHatPattern: boolean[];
  bassOnDownBeat: boolean;
  snareOnBackBeat: boolean;
}): Beat[] => {
  const subdivisionCount = getSubdivisionCount(params.beatTemplates);
  const pattern = getPaddedPatternSubdivisions(
    params.patternIds,
    subdivisionCount
  );
  const hiHatPattern = getRepeatedHiHatPattern(
    params.hiHatPattern,
    subdivisionCount
  );

  return params.beatTemplates.map((beatTemplate, beatIndex) => {
    const subdivisions = cloneBeatTemplateSubdivisions(
      beatTemplate.subdivisions
    );
    if (
      beatTemplate.type === "downBeat" &&
      params.bassOnDownBeat &&
      !subdivisions[0].includes("b")
    ) {
      subdivisions[0] = ["b"];
    }
    if (
      beatTemplate.type === "backBeat" &&
      params.snareOnBackBeat &&
      !subdivisions[0].includes("s")
    ) {
      subdivisions[0] = ["s"];
    }
    const beatTemplateWithForceOverrides: BeatTemplate = {
      type: beatTemplate.type,
      subdivisions,
    };
    const patternOffset = getSubdivisionCount(
      params.beatTemplates.slice(0, beatIndex)
    );
    const patternLength = beatTemplate.subdivisions.length;
    const patternSegment = pattern.slice(
      patternOffset,
      patternLength + patternOffset
    );
    const hiHatSegment = hiHatPattern.slice(
      patternOffset,
      patternLength + patternOffset
    );
    return generateBeat({
      beatTemplate: beatTemplateWithForceOverrides,
      patternSubdivisions: patternSegment,
      hiHatPattern: hiHatSegment,
    });
  });
};

// Generates beat subdivisions without the pattern, hi-hat and force overrides applied
export const generateBeatTemplateSubdivisions = (
  noteGenerationMode: NoteGenerationMode,
  subdivisionCount: number
): NoteTemplate[][] => {
  const getNoteTypes = (): NoteTemplate[] => {
    switch (noteGenerationMode) {
      case "b":
        return ["b"];
      case "s":
        return ["s"];
      case "d":
        return ["b", "s"];
      case "a":
        const aOptions: NoteTemplate[][] = [["b"], ["s"], ["b", "s"]];
        return aOptions[Math.floor(Math.random() * aOptions.length)];
      case "r":
        const rOptions: NoteTemplate[][] = [["b"], ["s"]];
        return rOptions[Math.floor(Math.random() * rOptions.length)];
    }
  };
  return Array(subdivisionCount).fill(undefined).map(getNoteTypes);
};

// Generates random beats from the time signature without the hi-hat and force overrides applied
export const generateRandomBeatTemplates = (
  timeSignature: TimeSignature,
  noteGenerationMode: NoteGenerationMode
): BeatTemplate[] =>
  timeSignature.barBeats.map((b) => ({
    type: b.beatType,
    subdivisions: generateBeatTemplateSubdivisions(
      noteGenerationMode,
      b.subdivisionCount
    ),
  }));
