import Abcjs from "./Abcjs";
import { generateBeatBarNotation } from "./notation";
import React, { FC } from "react";
import {
  defaultTimeSignatureId,
  getTimeSignature,
  TimeSignature,
} from "./timeSignature";
import {
  getPaddedPatternSubdivisions,
  getPatternIds,
  PatternId,
} from "./patterns";
import { generateBeat, generateBeatTemplateSubdivisions } from "./beat";

interface BeatsComponentProps {
  firstIncludedPatternId: PatternId;
  lastIncludedPatternId: PatternId;
}

const generateNotation = (
  timeSignature: TimeSignature,
  patternId: PatternId
): string =>
  generateBeatBarNotation(
    timeSignature,
    generateBeat({
      beatTemplate: {
        type: "normal",
        subdivisions: generateBeatTemplateSubdivisions("s", 4),
      },
      patternSubdivisions: getPaddedPatternSubdivisions([patternId], 4),
      hiHatPattern: [true, false, true, false],
    })
  );

const BeatsComponent: FC<BeatsComponentProps> = ({
  firstIncludedPatternId,
  lastIncludedPatternId,
}) => {
  const timeSignature = getTimeSignature(defaultTimeSignatureId);
  const patternIds = getPatternIds()
    .filter((id) => id >= firstIncludedPatternId && id <= lastIncludedPatternId)
    .sort();
  return (
    <Abcjs
      abcNotation={`
      L: 1/16
      K:C clef=perc
      V:T stem=down
      ${patternIds
        .slice(0, 5)
        .map((id) => generateNotation(timeSignature, id))
        .join("")}
      ${patternIds
        .slice(5, 10)
        .map((id) => generateNotation(timeSignature, id))
        .join("")}
      ${patternIds
        .slice(10)
        .map((id) => generateNotation(timeSignature, id))
        .join("")}
    `}
      abcVisualParams={{
        responsive: "resize",
        selectionColor: "currentColor",
        paddingleft: 0,
        paddingright: 0,
        format: {
          stretchlast: true,
        } as any,
      }}
    />
  );
};

export default BeatsComponent;
