import { subdivisionsPerPattern } from "./patterns";

export type TimeSignatureId = string;

export interface TimeSignature {
  id: TimeSignatureId;
  display: string;
  notation: string;
  barBeats: BeatConfig[];
}

export type BeatType = "downBeat" | "backBeat" | "normal";

interface BeatConfig {
  subdivisionCount: number;
  beatType: BeatType;
}

const makeBeat = (
  subdivisionCount: number,
  beatType: BeatType
): BeatConfig => ({
  subdivisionCount,
  beatType,
});

export const timeSignatures: TimeSignature[] = [
  {
    id: "44",
    display: "4/4 - backbeat on 2 and 4",
    notation: "4/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
    ],
  },
  {
    id: "340",
    display: "3/4 - no backbeat",
    notation: "3/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
    ],
  },
  {
    id: "342",
    display: "3/4 - backbeat on 3",
    notation: "3/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
    ],
  },
  {
    id: "34",
    display: "3/4 - backbeat on 2 and 3",
    notation: "3/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "backBeat"),
    ],
  },
  {
    id: "540",
    display: "5/4 - no backbeat",
    notation: "5/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
    ],
  },
  {
    id: "54",
    display: "5/4 - backbeat on 3 and 5",
    notation: "5/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
    ],
  },
  {
    id: "740",
    display: "7/4 - no backbeat",
    notation: "7/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
    ],
  },
  {
    id: "74",
    display: "7/4 - backbeat on 2, 4 and 6",
    notation: "7/4",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "backBeat"),
      makeBeat(4, "normal"),
    ],
  },
  {
    id: "780",
    display: "7/8 (2,2,3) - no backbeat",
    notation: "7/8",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(6, "normal"),
    ],
  },
  {
    id: "781",
    display: "7/8 (2,3,2) - no backbeat",
    notation: "7/8",
    barBeats: [
      makeBeat(4, "downBeat"),
      makeBeat(6, "normal"),
      makeBeat(4, "normal"),
    ],
  },
  {
    id: "782",
    display: "7/8 (3,2,2) - no backbeat",
    notation: "7/8",
    barBeats: [
      makeBeat(6, "downBeat"),
      makeBeat(4, "normal"),
      makeBeat(4, "normal"),
    ],
  },
];

export const defaultTimeSignatureId: TimeSignatureId = "44";

export const getTimeSignature = (id: TimeSignatureId): TimeSignature => {
  const result = timeSignatures.find((ts) => ts.id === id);
  if (result === undefined) {
    throw new Error(`Unrecognised time signature ID: ${id}`);
  }
  return result;
};

export const getTimeSignatureOrDefault = (id: TimeSignatureId): TimeSignature =>
  timeSignatures.find((ts) => ts.id === id) ??
  getTimeSignature(defaultTimeSignatureId);

export const filterValidTimeSignatureId = (
  input: string
): TimeSignatureId | undefined =>
  timeSignatures.find((ts) => ts.id === input)?.id;

// Returns the number of patterns required to fill all subdivisions in a full bar of the time signature
export const getPatternCount = (timeSignature: TimeSignature) => {
  const subdivisionCount = timeSignature.barBeats.reduce(
    (acc, beatConfig) => acc + beatConfig.subdivisionCount,
    0
  );
  const patternCount = subdivisionCount / subdivisionsPerPattern;
  return Math.ceil(patternCount);
};
