import { TimeSignature } from "./timeSignature";
import { PatternId } from "./patterns";
import { Note } from "./note";
import { Beat } from "./beat";

interface SubdivisionNotationData {
  beatIndex: number; // The index of the beat within the groove
  subdivisionIndex: number; // The index of this subdivision within the beat
  notes: Note[]; // The collection of notes to render
  duration: number; // Duration of the notes in number of subdivisions
  patternIds: PatternId[] | undefined; // Pattern IDs if no notes are played on subsequent pattern starts
}

export type BeatNotationData = SubdivisionNotationData[];

const getNoteSymbol = (note: Note) => {
  switch (note) {
    case "b":
      return "F";
    case "s":
      return "c";
    case "h":
      return "!style=x!g";
  }
};

const generateSubdivisionNotation = (data: SubdivisionNotationData) => {
  const noteSymbols = data.notes.map(getNoteSymbol);
  const noteString = noteSymbols
    .map((noteSymbol) => `${noteSymbol}${data.duration}`)
    .join("");
  const restNote = `z${data.duration}`;
  const patternId = data.patternIds ? `"${data.patternIds.join("")}"` : "";
  const notes = noteString.length > 0 ? `[${noteString}]` : `[${restNote}]`;
  return `${patternId}${notes}`;
};

const generateBeatNotation = (data: SubdivisionNotationData[]) =>
  data.map(generateSubdivisionNotation).join("");

const calculateBeatNotationData = (
  timeSignature: TimeSignature,
  beat: Beat,
  beatIndex: number
): BeatNotationData => {
  const subdivisionNotationDataArray: SubdivisionNotationData[] = [];
  let currentIndex = 0;
  while (currentIndex < beat.subdivisions.length) {
    const unassignedPatternIds: PatternId[] = [];
    const patternId = beat.subdivisions[currentIndex].patternId;
    if (patternId !== undefined) {
      unassignedPatternIds.push(patternId);
    }
    let nextIndex = currentIndex + 1;
    while (
      nextIndex < beat.subdivisions.length &&
      beat.subdivisions[nextIndex].notes.length === 0
    ) {
      const patternId = beat.subdivisions[nextIndex].patternId;
      if (patternId !== undefined) {
        unassignedPatternIds.push(patternId);
      }
      nextIndex++;
    }
    subdivisionNotationDataArray.push({
      beatIndex,
      subdivisionIndex: currentIndex,
      notes: beat.subdivisions[currentIndex].notes,
      duration: nextIndex - currentIndex,
      patternIds: unassignedPatternIds,
    });
    currentIndex = nextIndex;
  }
  return subdivisionNotationDataArray;
};

export const calculateGrooveNotationData = (
  timeSignature: TimeSignature,
  beats: Beat[]
): BeatNotationData[] => {
  return timeSignature.barBeats.map((b, beatIndex) => {
    const beat: Beat = beats[beatIndex] ?? {
      type: "normal",
      subdivisions: Array(b.subdivisionCount)
        .fill(undefined)
        .map(() => ({ notes: [], patternId: undefined })),
    };
    return calculateBeatNotationData(timeSignature, beat, beatIndex);
  });
};

export const generateBeatBarNotation = (
  timeSignature: TimeSignature,
  beat: Beat
) => {
  const data = calculateBeatNotationData(timeSignature, beat, 0);
  return `|:${generateBeatNotation(data)}:|`;
};

export const generateGrooveBarNotation = (
  timeSignature: TimeSignature,
  beats: Beat[]
) => {
  const grooveData = calculateGrooveNotationData(timeSignature, beats);
  return `|:${grooveData.map(generateBeatNotation).join(" ")}:|`;
};
