import React from "react";
import { generateGrooveBarNotation } from "./notation";
import Abcjs from "./Abcjs";
import { TimeSignature } from "./timeSignature";
import { Beat } from "./beat";

interface GrooveProps {
  timeSignature: TimeSignature;
  beats: Beat[];
  onClick: (noteIndex: number) => void;
}

const GrooveComponent: React.FC<GrooveProps> = ({
  timeSignature,
  beats,
  onClick,
}) => {
  return (
    <Abcjs
      abcNotation={`
        M: ${timeSignature.notation}
        L: 1/16
        K:C clef=perc
        V:T stem=down
        ${generateGrooveBarNotation(timeSignature, beats)}
      `}
      abcVisualParams={{
        responsive: "resize",
        selectionColor: "currentColor",
        paddingleft: 0,
        paddingright: 0,
        format: {
          stretchlast: true,
        } as any,
        clickListener: (_elem, _tune, _classes, _analysis, drag) => {
          onClick(drag.index);
        },
      }}
    />
  );
};

export default GrooveComponent;
