const noteTemplateOptions = [
  "b", // bass
  "s", // snare
];

const noteOptions = [
  "b", // bass
  "s", // snare
  "h", // hi-hat
] as const;

export type NoteTemplate = (typeof noteTemplateOptions)[number];

export type Note = (typeof noteOptions)[number];

export const validNote = (note: string) => noteTemplateOptions.includes(note);

export const convertNotesToNoteTemplates = (notes: Note[]): NoteTemplate[] =>
  notes.filter((n) => noteTemplateOptions.includes(n));
