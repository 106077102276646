import chunk from "lodash.chunk";
import { BeatType, TimeSignature } from "./timeSignature";

export const subdivisionsPerPattern = 4;

const patternStrings: { [key: string]: string } = {
  A: "x---",
  B: "--x-",
  C: "x-x-",
  D: "-x--",
  E: "---x",
  F: "xx--",
  G: "-xx-",
  H: "--xx",
  I: "x--x",
  J: "-x-x",
  K: "xxx-",
  L: "-xxx",
  M: "x-xx",
  N: "xx-x",
  O: "xxxx",
};

export type PatternId = keyof typeof patternStrings;

export interface PatternSubdivision {
  patternId: PatternId | undefined;
  isPlayed: boolean;
  isFirst: boolean;
}

export const getPatternIds = (): PatternId[] => Object.keys(patternStrings);

// Gets concatenated subdivisions padded out with un-played subdivisions to at least the specified min length
export const getPaddedPatternSubdivisions = (
  patternIds: PatternId[],
  minLength: number
): PatternSubdivision[] => {
  const patternSubdivisions: PatternSubdivision[] = patternIds.flatMap((id) =>
    patternStrings[id].split("").map((type, index) => ({
      patternId: id,
      isPlayed: type === "x",
      isFirst: index === 0,
    }))
  );
  const missingLength = Math.max(minLength - patternSubdivisions.length, 0);
  patternSubdivisions.push(
    ...Array(missingLength).fill({
      patternId: undefined,
      isPlayed: false,
      isFirst: false,
    })
  );
  return patternSubdivisions;
};

// Repeats a hi-hat pattern to at least the specified min length
export const getRepeatedHiHatPattern = (
  hiHatPattern: boolean[],
  minLength: number
): boolean[] => {
  const pattern = hiHatPattern.length > 0 ? hiHatPattern : [false];
  const requiredRepeats = Math.ceil(minLength / pattern.length);
  return Array(requiredRepeats).fill(pattern).flat();
};

// Picks enough patterns to satisfy the time signature and tries to ensure a note is available
// on the beat if required for a force override
export const generateRandomPatternIds = (params: {
  timeSignature: TimeSignature;
  bassOnDownBeat: boolean;
  snareOnBackBeat: boolean;
  firstIncludedPatternId: PatternId;
  lastIncludedPatternId: PatternId;
}): PatternId[] => {
  const availablePatternIds = Object.keys(patternStrings).filter(
    (id) =>
      id >= params.firstIncludedPatternId && id <= params.lastIncludedPatternId
  );

  // Beats can have varying numbers of subdivisions, but patterns have a fixed number,
  // so create a flat array and then re-partition it into pattern-sized chunks
  const subdivisionTypes: BeatType[] = [];
  for (const beatConfig of params.timeSignature.barBeats) {
    subdivisionTypes.push(beatConfig.beatType);
    subdivisionTypes.push(
      ...Array(beatConfig.subdivisionCount - 1).fill("normal")
    );
  }

  const patternNoteTypesArray = chunk(
    subdivisionTypes,
    subdivisionsPerPattern
  ) as BeatType[][];

  return patternNoteTypesArray.map((patternNoteTypes) => {
    // Determine which notes in the pattern must be played
    const requiredPlayedSubdivisions = patternNoteTypes.reduce<number[]>(
      (acc, noteType, index) => {
        const isNoteRequired =
          (noteType === "downBeat" && params.bassOnDownBeat) ||
          (noteType === "backBeat" && params.snareOnBackBeat);
        return isNoteRequired ? [...acc, index] : acc;
      },
      []
    );

    // Remove patterns that don't have a played note when one is required
    const candidatePatternIds = availablePatternIds.filter((id) => {
      for (const requiredPlayedSubdivision of requiredPlayedSubdivisions) {
        if (patternStrings[id][requiredPlayedSubdivision] !== "x") {
          return false;
        }
      }
      return true;
    });

    // If there are no candidates that match all the criteria, just return the first pattern ID
    if (candidatePatternIds.length === 0) {
      return availablePatternIds[0];
    }

    // If there are candidates, randomly select one
    const selectedCandidateIdIndex = Math.floor(
      Math.random() * candidatePatternIds.length
    );
    return candidatePatternIds[selectedCandidateIdIndex];
  });
};
